/* Focusin/out event polyfill (for Firefox) by nuxodin
 * Source: https://gist.github.com/nuxodin/9250e56a3ce6c0446efa
 */


$(document).ready(function() {




    !function(){
        var w = window,
            d = w.document;

        if( w.onfocusin === undefined ){
            d.addEventListener('focus' ,addPolyfill ,true);
            d.addEventListener('blur' ,addPolyfill ,true);
            d.addEventListener('focusin' ,removePolyfill ,true);
            d.addEventListener('focusout' ,removePolyfill ,true);
        }
        function addPolyfill(e){
            var type = e.type === 'focus' ? 'focusin' : 'focusout';
            var event = new CustomEvent(type, { bubbles:true, cancelable:false });
            event.c1Generated = true;
            e.target.dispatchEvent( event );
        }
        function removePolyfill(e){
            if(!e.c1Generated){ // focus after focusin, so chrome will the first time trigger tow times focusin
                d.removeEventListener('focus' ,addPolyfill ,true);
                d.removeEventListener('blur' ,addPolyfill ,true);
                d.removeEventListener('focusin' ,removePolyfill ,true);
                d.removeEventListener('focusout' ,removePolyfill ,true);
            }
            setTimeout(function(){
                d.removeEventListener('focusin' ,removePolyfill ,true);
                d.removeEventListener('focusout' ,removePolyfill ,true);
            });
        }
    }();


    $('.home-slideshow__title-inner-wrap span').each(function(){
        $(this).attr('original-width', $(this).outerWidth());
    });

    // Progress timer function

    var Timer = function(cb, delay) {
        this.cb = cb;
        this.delay = delay;
        this.elapsed = 0;
        this.remaining = this.delay - self.elapsed;
    };

    var slidesProgressed = 0;
    var bar = document.getElementById('bar');
    var info = document.getElementById('info');
    var circleProg = document.querySelector('.home-slideshow__tab-deco circle');
    var percent;
    var circleProgPercent;

    Timer.prototype = function() {
            var _start = function(x, y) {
                var self = this;
                if (self.elapsed < self.delay) {
                    clearInterval(self.interval);
                    self.interval = setInterval(function() {
                        self.elapsed += 50;
                        self.remaining = self.delay - self.elapsed;
                        percent = Math.round(self.elapsed/self.delay * 100);
                        circleProgPercent = 100 - percent;

                        var progCircles = document.querySelectorAll('.current .circle-container__progress');

                        progCircles.forEach(function(el) {
                            el.style.strokeDashoffset = circleProgPercent;
                        });

                        if (self.elapsed >= self.delay) {
                            clearInterval(self.interval);
                            self.cb();
                        }
                    }, 50);
                }
            },
            _pause = function() {
                var self = this;
                clearInterval(self.interval);
            },
            _restart = function() {
                var self = this;
                self.elapsed = 0;
                clearInterval(self.interval);
                self.start();
            };

        //public member definitions
        return {
            start: _start,
            pause: _pause,
            restart: _restart
        };
    }();





    /*
       Carousel Prototype
       Eric Eggert for W3C
    */

    var myCarousel = (function() {

        "use strict";

        // Initial variables
        var carousel, slides, index, slidenav, settings, timer, setFocus, animationSuspended;

        animationSuspended = false;

        var slideshow = $('.home-slideshow');
        // Helper function: Iterates over an array of elements
        function forEachElement(elements, fn) {
            for (var i = 0; i < elements.length; i++)
                fn(elements[i], i);
        }


        // Commented out to stop timer

        var progTimer = new Timer(function() {
           // info.innerHTML += ' <b>Done!</b>';
            nextSlide();
        }, 8000);

        // Helper function: Remove Class
        function removeClass(el, className) {
            if (el.classList) {
                el.classList.remove(className);
            } else {
                el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

        // Helper function: Test if element has a specific class
        function hasClass(el, className) {
            if (el.classList) {
                return el.classList.contains(className);
            } else {
                return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
            }
        }

        // Initialization for the carousel
        // Argument: set = an object of settings
        // Possible settings:
        // id <string> ID of the carousel wrapper element (required).
        // slidenav <bool> If true, a list of slides is shown.
        // animate <bool> If true, the slides can be animated.
        // startAnimated <bool> If true, the animation begins
        //                        immediately.
        //                      If false, the animation needs
        //                        to be initiated by clicking
        //                        the play button.
        function init(set) {

            // Make settings available to all functions
            settings = set;

            // Select the element and the individual slides
            carousel = document.getElementById(settings.id);
            slides = carousel.querySelectorAll('.slide');

            carousel.className = 'active carousel';


            // Create unordered list for controls, and attach click events fo previous and next slide
            var ctrls = document.createElement('ul');

            ctrls.className = 'controls';
            // ctrls.innerHTML = '<li>' +
            //     '<button type="button" class="btn-prev"><span class="visually-hidden">Previous Item</span></button>' +
            //     '</li>' +
            //     '<li>' +
            //     '<button type="button" class="btn-next"><span class="visually-hidden">Next Item</span></button>' +
            //     '</li>';

            // ctrls.querySelector('.btn-prev')
            //     .addEventListener('click', function () {
            //         console.log("NEXT CLICK");
            //         prevSlide(true);
            //     });
            // ctrls.querySelector('.btn-next')
            //     .addEventListener('click', function () {
            //         console.log("NEXT CLICK");
            //         nextSlide(true);
            //     });





            // If the carousel is animated or a slide navigation is requested in the settings, anoter unordered list that contains those elements is added. (Note that you cannot supress the navigation when it is animated.)
            if (settings.slidenav || settings.animate) {
                slidenav = document.createElement('ul');

                slidenav.className = 'slidenav home-slideshow__tabs';



                if (settings.slidenav) {
                    forEachElement(slides, function(el, i){
                        var li = document.createElement('li');
                        var siteNamePrimary = $(el).attr('slide-name-primary');
                        var siteNameSecondary = $(el).attr('slide-name-secondary');
                        var siteSlug = $(el).attr('active-site');
                       // console.log(siteSlug);
                        var klass = (i===0) ? 'class="current" ' : '';
                        var kurrent = (i===0) ? ' <span class="visuallyhidden">(Current Item)</span>' : '';
                        li.innerHTML = '<button '+ klass +'data-slide="' + i + '" data-sitename-primary="'+siteNamePrimary+'" data-sitename-secondary="'+siteNameSecondary+'" data-siteslug="'+siteSlug+'"><span class="home-slideshow__tab-text-large"></span>' + siteNamePrimary + siteNameSecondary + kurrent + '</span></button>';
                        slidenav.appendChild(li);
                    });
                }

                $(document).on('click', '.home-slideshow__tabs button', function(event) {

                    setSlides($(this).attr('data-slide'), true);
                });

                $(document).on('click', '.home-slideshow .controls', function(event) {
                    //console.log(event);
                    var button = event.target;

                    if (button.localName == 'button') {
                        if (button.getAttribute('data-action') == "stop") {
                            stopAnimation();
                        } else if (button.getAttribute('data-action') == "start") {
                            startAnimation();
                        }
                    }
                });

                carousel.className = 'active carousel with-slidenav';
                var slideNavWrapperExterior = document.createElement('div');
                slideNavWrapperExterior .className = 'home-slideshow__tabs-wrapper';



                var slideNavWrapper = document.createElement('div');
                slideNavWrapper .className = 'wrapper';



                // var slideNavWrapperInner = document.createElement('div');
                // slideNavWrapperInner.className = 'wrapper';
               // slideNavWrapper.appendChild(slideNavWrapperInner);
                slideNavWrapper.appendChild(slidenav);

                slideNavWrapperExterior.appendChild(slideNavWrapper);

                //carousel.appendChild(slideNavWrapper);
                slideshow.append(slideNavWrapperExterior);



                slideshow.append(ctrls);

                if (settings.animate) {
                    var li = document.createElement('li');

                    if (settings.startAnimated) {
                        li.innerHTML = '<button class="home-slideshow__btn-start" data-action="stop"><span class="visuallyhidden">Stop Animation </span></button>';
                        document.querySelector('.home-slideshow').classList.remove('is-paused');

                    } else {
                        li.innerHTML = '<button class="home-slideshow__btn-start" data-action="start"><span class="visuallyhidden">Start Animation </span></button>';
                        document.querySelector('.home-slideshow').classList.add('is-paused');
                    }

                    ctrls.appendChild(li);
                }
            }

            // Add a live region to announce the slide number when using the previous/next buttons
            var liveregion = document.createElement('div');
            liveregion.setAttribute('aria-live', 'polite');
            liveregion.setAttribute('aria-atomic', 'true');
            liveregion.setAttribute('class', 'liveregion visuallyhidden');
            carousel.appendChild(liveregion);

            // After the slide transitioned, remove the in-transition class, if focus should be set, set the tabindex attribute to -1 and focus the slide.
            slides[0].parentNode.addEventListener('transitionend', function (event) {
                var slide = event.target;
                removeClass(slide, 'in-transition');
                if (hasClass(slide, 'current'))  {
                    if(setFocus) {
                        slide.setAttribute('tabindex', '-1');
                        slide.focus();
                        setFocus = false;
                    }
                }
            });

            // When the mouse enters the carousel, suspend the animation.
          //  carousel.addEventListener('mouseenter', suspendAnimation);

            // When the mouse leaves the carousel, and the animation is suspended, start the animation.
            carousel.addEventListener('mouseleave', function(event) {
                if (animationSuspended) {
                  //  startAnimation();
                }
            });

            // When the focus enters the carousel, suspend the animation
            carousel.addEventListener('focusin', function(event) {
                if (!hasClass(event.target, 'slide')) {
                    //suspendAnimation();
                }
            });

            // When the focus leaves the carousel, and the animation is suspended, start the animation
            carousel.addEventListener('focusout', function(event) {
                if (!hasClass(event.target, 'slide') && animationSuspended) {
                  //  startAnimation();
                }
            });

            // Set the index (=current slide) to 0 – the first slide
            index = 0;
            setSlides(index);

            // If the carousel is animated, advance to the
            // next slide after 5s
            if (settings.startAnimated) {
              //  timer = setTimeout(nextSlide, 5000);
                progTimer.start();
            }
        }

        // Function to set a slide the current slide
        function setSlides(new_current, setFocusHere, transition, announceItemHere) {
            // Focus, transition and announce Item are optional parameters.
            // focus denotes if the focus should be set after the
            // carousel advanced to slide number new_current.
            // transition denotes if the transition is going into the
            // next or previous direction.
            // If announceItem is set to true, the live region’s text is changed (and announced)
            // Here defaults are set:

            setFocus = typeof setFocusHere !== 'undefined' ? setFocusHere : false;
            transition = typeof transition !== 'undefined' ? transition : 'none';
            var announceItem = typeof announceItemHere !== 'undefined' ? announceItemHere : false;

            new_current = parseFloat(new_current);

            var length = slides.length;
            var new_next = new_current+1;
            var new_prev = new_current-1;

            // If the next slide number is equal to the length,
            // the next slide should be the first one of the slides.
            // If the previous slide number is less than 0.
            // the previous slide is the last of the slides.
            if(new_next === length) {
                new_next = 0;
            } else if(new_prev < 0) {
                new_prev = length-1;
            }

            // Reset slide classes
            for (var i = slides.length - 1; i >= 0; i--) {
                slides[i].className = "slide";
            }

            // Add classes to the previous, next and current slide
            slides[new_next].className = 'next slide ' + ((transition == 'next') ? ' in-transition' : '');
            slides[new_next].setAttribute('aria-hidden', 'false');

            slides[new_prev].className = 'prev slide' + ((transition == 'prev') ? ' in-transition' : '');
            slides[new_prev].setAttribute('aria-hidden', 'false');

            slides[new_current].className = 'current slide';
            slides[new_current].removeAttribute('aria-hidden');



             //slideBody.setAttribute('tabindex', '0');

            // console.log(slideBody);

            var prevSlideSlug = $('.home-slideshow__tabs button.current').attr('data-siteslug');
            var currentSlideSlug = slides[new_current].getAttribute('active-site');

            slideshow.attr('active-site', currentSlideSlug);

            var currentSlideVideo = slides[new_current].querySelectorAll('video');
            if (currentSlideVideo.length > 0){
                currentSlideVideo[0].currentTime = 0;
            }


            // $('.home-slideshow__title-inner-wrap span').removeClass('is-visible').removeClass('is-transitioning-out');
            // $('.home-slideshow__title-inner-wrap').find('span[slug='+prevSlideSlug+']').addClass('is-transitioning-out');
            // setTimeout(function(){
            //
            //     $('.home-slideshow__title-inner-wrap span[slug='+currentSlideSlug+']').addClass('is-visible');
            //     $('.home-slideshow__title-inner-wrap').find('span[slug='+prevSlideSlug+']').removeClass('is-transitioning-out');
            // }, 400);
            //



            $('.home-slideshow__title-inner-wrap').attr('active-site', currentSlideSlug);
            $('.home-slideshow__title-inner-wrap').css('width', $('.home-slideshow__title-inner-wrap span.is-visible').attr('original-width'));
            // Update the text in the live region which is then announced by screen readers.
            if (announceItem) {
                carousel.querySelector('.liveregion').textContent = 'Item ' + (new_current + 1) + ' of ' + slides.length;
            }

            // Update the buttons in the slider navigation to match the currently displayed  item
            if(settings.slidenav) {
                var buttons = slideshow.find('.slidenav button[data-slide]');
                for (var j = buttons.length - 1; j >= 0; j--) {
                    buttons[j].className = '';
                    var siteSlug = $(buttons[j]).attr('data-siteslug');
                    buttons[j].setAttribute('active-site', siteSlug);
                    // Active
                  //  console.log(buttons[j]);
                    var siteNamePrimary = $(buttons[j]).attr('data-sitename-primary');
                    var siteNameSecondary = $(buttons[j]).attr('data-sitename-secondary');

                    buttons[j].innerHTML = '<div><span class="home-slideshow__tab-text-primary">' + siteNamePrimary + '</span></div><span class="home-slideshow__tab-deco"><svg class="circle-container" viewBox="2 -2 28 36" xmlns="http://www.w3.org/2000/svg"><circle class="circle-container__background" r="16" cx="16" cy="16"></circle><circle class="circle-container__progress" r="16"cx="16"cy="16"></circle></svg></span>';
                }


                var siteNamePrimary = $(buttons[new_current]).attr('data-sitename-primary');
                var siteNameSecondary = $(buttons[new_current]).attr('data-sitename-secondary');
                var siteSlug = $(buttons[new_current]).attr('data-siteslug');
                buttons[new_current].className = "current";
                buttons[new_current].setAttribute('active-site', siteSlug);
                // Inactive
                buttons[new_current].innerHTML = '<div><span class="home-slideshow__tab-text-primary">' + siteNamePrimary + '</span><span class="visuallyhidden">(Current Item)</span></div><span class="home-slideshow__tab-deco"><svg class="circle-container" viewBox="2 -2 28 36" xmlns="http://www.w3.org/2000/svg"><circle class="circle-container__background" r="16" cx="16" cy="16"></circle><circle class="circle-container__progress" r="16"cx="16"cy="16"></circle></svg></span>';
            }

            // Set the global index to the new current value
            index = new_current;

            // var startStopButton = slideshow.find('[data-action]');
            // startStopButton.innerHTML = '<span class="visually-hidden">Stop Animation </span>';
            // startStopButton.attr('data-action', 'stop');
          //  console.log(animationSuspended);
            if (settings.startAnimated) {
                if (animationSuspended == false) {
                    progTimer.restart();
                }
            }


            // if (slidesProgressed >= 1){
            //     let slideshowElement = document.querySelector('.home-slideshow');
            //     if (slideshowElement.contains(document.activeElement)){
            //
            //     }
            // }



            slidesProgressed++;
        }




        // Function to advance to the next slide
        function nextSlide(announceItem) {
            //console.log("GOIN NEXT");
            var announceItem = typeof announceItem !== 'undefined' ? announceItem : false;

            var length = slides.length,
                new_current = index + 1;

            if(new_current === length) {
                new_current = 0;
            }

            // If we advance to the next slide, the previous needs to be
            // visible to the user, so the third parameter is 'prev', not
            // next.
            setSlides(new_current, false, 'prev', announceItem);

            // If the carousel is animated, advance to the next
            // slide after 5s
            if (settings.animate) {
                progTimer.restart();
            }


        }

        // Function to advance to the previous slide
        function prevSlide(announceItem) {
            var announceItem = typeof announceItem !== 'undefined' ? announceItem : false;
            //  announceItem = true;

            var length = slides.length,
                new_current = index - 1;

            // If we are already on the first slide, show the last slide instead.
            if(new_current < 0) {
                new_current = length-1;
            }

            // If we advance to the previous slide, the next needs to be
            // visible to the user, so the third parameter is 'next', not
            // prev.
            setSlides(new_current, false, 'next', announceItem);

        }

        //var progress = document.querySelector("#progress");

        // Function to stop the animation
        function stopAnimation() {
          //  clearTimeout(timer);
            settings.animate = false;
            animationSuspended = true;
            var _this = slideshow.find('[data-action]');
            _this.innerHTML = '<span class="visually-hidden">Start Animation </span>';
            _this.attr('data-action', 'start');
            progTimer.pause();
            document.querySelector('.home-slideshow').classList.add('is-paused');
            $( ".carousel video" ).each(function( index ) {
                $(this)[0].pause();
            });

        }

        // Function to start the animation
        function startAnimation() {
            settings.animate = true;
            animationSuspended = false;
            var _this = slideshow.find('[data-action]');
            _this.innerHTML = '<span class="visually-hidden">Stop Animation </span>';
            _this.attr('data-action', 'stop');
            progTimer.start();
            document.querySelector('.home-slideshow').classList.remove('is-paused');
            console.log("STARTING");
            $( ".carousel video" ).each(function( index ) {
                $(this)[0].play();
            });
        }

        // Function to suspend the animation
        function suspendAnimation() {
            console.log("suspending animation");
            if(settings.animate) {
              //  clearTimeout(timer);
                settings.animate = false;
                animationSuspended = true;
                progTimer.pause();
            }
        }

        //Function to run progress animation
        // function runProgressAnimation(){
        //     progress.classList.remove('runProgress');
        //     setTimeout(function(){
        //         //seems to need a brief pause to reset
        //         progress.classList.add('runProgress');
        //     }, 100);
        // }

        //first run
       // runProgressAnimation();

        // Making some functions public
        return {
            init:init,
            next:nextSlide,
            prev:prevSlide,
            goto:setSlides,
            stop:stopAnimation,
            start:startAnimation
        };
    });



    var videoIsPlaying = false;

    // Homepage video Start
    // **************************************
    if ($('.home-slideshow').length > 0){
        var carousel = new myCarousel();

        var $video = $('#home-hero-video'),
            videoElement = $video[0];
        $video.on('canplaythrough', playHomepageHeroVideo);


        // $( ".home-slideshow video" ).each(function( index ) {
        //     if ($(window).width() > 550) {
        //         $(this).attr('src', $(this).attr('video-src'));
        //     } else {
        //         $(this).attr('src', $(this).attr('video-src').replace('/hero/', '/card/'))
        //     }
        // });



        if (videoElement.readyState > 3) {
            playHomepageHeroVideo();
        }
    }

    function playHomepageHeroVideo(){
        if(!videoIsPlaying){
            $('body').addClass('is-home-hero-video-loaded')
            carousel.init({
                id: 'home-carousel',
                slidenav: true,
                animate: true,
                startAnimated: true
            });
            videoIsPlaying = true;
        }
    }


    $(document).on('click', '.home-slideshow__tabs button', function(){

     setTimeout(function(){
         let slideBody = $('.slide.current .home-slideshow__primary');
         slideBody.attr('tabindex', '0');
         slideBody.focus();
         // slideBody.attr('tabindex', '');
     }, 100);

    });

    // Homepage video End
    // **************************************
});


