//************************** */
// * Learning Center Search * 
//*********************** */

/*  
    Read search-projects.js and search-media.js for code explanation
*/
    let learning__year = '';
    if (document.querySelector('.year-timeline')){
        learning__year = new Date().getFullYear();
    }

    let learning__state = {
        query:'',
        solution:'',
        capability:'',
        region:'',
        downloadType:'',    
        year: learning__year 
    }
   

function learning__initalLoad(){

    const urlParams = new URLSearchParams(window.location.search);
    const queryParam = urlParams.get('query')
    const solutionParam = urlParams.get('solution')
    const capabilityParam = urlParams.get('capability')
    const regionParam = urlParams.get('region')
    const yearParam = urlParams.get('year')
    const downloadTypeParam = urlParams.get('downloadType')

    let paramObject = {}

    if (queryParam){ paramObject['query'] = queryParam }
    if (solutionParam){ paramObject['solution'] = solutionParam.toLowerCase() }
    if (capabilityParam){ paramObject['capability'] = capabilityParam.toLowerCase() }
    if (regionParam){ paramObject['region'] = regionParam.toLowerCase() }
    if (yearParam){ paramObject['year'] = yearParam }
    if (downloadTypeParam){ paramObject['downloadType'] = downloadTypeParam }

    learning__updateState(paramObject)
}


function learning__updateState(stateChange, source){
    //Update the state with the new changes (or reset them)

    if (source === "reset"){
        learning__state['solution'] = ''
        learning__state['capability'] = ''
        learning__state['region'] = ''
        learning__state['downloadType'] = ''
        learning__state['year'] = learning__year
    } else {
        if (stateChange !== null && typeof stateChange === 'object'){
            Object.entries(learning__state).forEach(([keyA, valueA]) => {
                Object.entries(stateChange).forEach(([keyB, valueB]) => {
                    if (keyA === keyB){
                        if (valueB === null){
                            learning__state[keyA] = ''
                        } else {
                            learning__state[keyA] = valueB
                        }
                    }
                })
            });
        }
    }
    
    if (source === "reset"){
        learning__resetFilters()
    }
    if (source !== "params"){
        let urlParams = new URLSearchParams('');
        let paramsAreEmpty = true;
        Object.entries(learning__state).forEach(([key, value]) => {
            if (value !== ''){
                urlParams.set(key, value)
                paramsAreEmpty = false;
            }
        })
        let showParamMark = paramsAreEmpty ? '' : '?'
        window.history.replaceState({}, '', `${window.location.pathname}${showParamMark}${urlParams}`);
    }
    learning__updateFilters() 
    learning__updateResults()
}


function learning__resetFilters(){
    // Unhighlight any active dropdown items
    let activeDropdownItems = document.querySelectorAll('.dropdown-item--active');
    activeDropdownItems.forEach(item => {
        item.classList.remove('dropdown-item--active');
    })

    // Highlight the default "all __" option again
    document.querySelector('.all-solution').classList.add('dropdown-item--active');
    document.querySelector('.all-capability').classList.add('dropdown-item--active');
    document.querySelector('.all-region').classList.add('dropdown-item--active');

    // Change all dropdown inner text to "All __"
    document.querySelector('.dropdown-toggle__selection--solution').innerText = "All Solutions";
    document.querySelector('.dropdown-toggle__selection--capability').innerText = "All Capabilities";
    document.querySelector('.dropdown-toggle__selection--region').innerText = "Global";


    let downloadTypes = document.querySelectorAll(".download-type-button");
    let downloadAccessibilityPrompt = document.querySelector('.download-current-type');

    if (downloadTypes){
        downloadTypes.forEach( type => {
            type.classList.remove("download-type-button--active")
        })
        let allDownloads =  document.querySelector(".download-type-button[data-value='allDownloads']");
        if (allDownloads) {
            allDownloads.classList.add("download-type-button--active")
        }
        if (downloadAccessibilityPrompt){
            downloadAccessibilityPrompt.innerHTML = `Currently no active filter, showing all results`;
        }
    } 

}


function learning__updateFilters(){
    learning__resetFilters()
    //Loop through state and update the filter dropdowns of any active types
    Object.entries(learning__state).forEach(([key, value]) => {
        if (key === 'region' || key === 'theme' || key === 'capability' || key === 'solution' || key === 'innovation'){
            if (value !== ''){
                let filter = document.querySelector(`.${key}-option[data-${key}=${value}]`)
                if (filter){
                    document.querySelector(`.all-${key}`).classList.remove('dropdown-item--active'); //first remove the all filter
                    filter.classList.add('dropdown-item--active')
                    let filterTitle = filter.querySelector('span').innerHTML;
                    document.querySelector(`.dropdown-toggle__selection--${key}`).innerHTML = filterTitle;
                } 
            }
            else if (value === ''){
                // Add active class to "all __" option when it is clicked (i.e. no filter)
                document.querySelector(`.all-${key}`).classList.add('dropdown-item--active');
            }
        }
        if (key === 'query' && value !== ''){
            document.querySelector('#learning-search').value = value;
        }
        if (key === 'downloadType' && value !== ''){
            let downloadTypes = document.querySelectorAll(".download-type-button");
            if (downloadTypes){
                downloadTypes.forEach( type => {
                    type.classList.remove("download-type-button--active")
                })
            } 
            let downloadInput = document.querySelector(`.download-type-button[data-value="${value}"`);
            let downloadInnerText = downloadInput.querySelector('.download-type-button--inner-text');
            let downloadAccessibilityPrompt = document.querySelector('.download-current-type');
            if (downloadInput){
                downloadInput.classList.add("download-type-button--active");
                if (downloadAccessibilityPrompt && downloadInnerText){
                    downloadAccessibilityPrompt.innerHTML = `Currently filtering by ${downloadInnerText.innerHTML}`;
                }
            }
        }
    })
}


function learning__watchFilters(){

    ['capability', 'solution', 'region'].forEach(( type ) => {
        let filters = document.querySelectorAll(`.${type}-option`);
        if (filters){
            filters.forEach((filter) => {
                filter.addEventListener('click', function(e){
                    if (type === 'capability' || type === 'solution'){
                        if(filter.classList.contains('is-open') || filter.classList.contains('is-open--nested')) {
                            return filter;
                        }
                    }
                    let result = {}
                    result[type] = e.currentTarget.getAttribute(`data-${type}`);
                    learning__updateState(result, 'filters')
                })
                filter.addEventListener('keypress', function(e){
                    if(e.key === "Enter") {
                        let result = {}
                        result[type] = e.currentTarget.getAttribute(`data-${type}`);
                        learning__updateState(result, 'filters')
                    }
                })
            })
        }
    })

    let downloadTypes = document.querySelectorAll('.download-type-button');
    if (downloadTypes) {
        downloadTypes.forEach ( button => {
            button.addEventListener("click", function(e){
                let result = {};
                let buttonType = e.currentTarget.getAttribute("data-value");
                downloadTypes.forEach( btn => {
                    if (btn.getAttribute("data-value") !== buttonType){
                        btn.classList.remove('download-type-button--active');
                    } else {
                        btn.classList.add('download-type-button--active');
                    }
                })
                result['downloadType'] = buttonType;
                learning__updateState(result);

            })
        })
    }

    //Watch the search box 
    $('#learning-search').keyup(_.debounce(function(event){
        let result = { query : event.target.value }
        learning__updateState(result)
    } , 500)); 
}

function learning__watchTimeline(){
    let years = document.querySelectorAll('.year-timeline__year-btn');
    if ( years ){
        years.forEach( year => {
            year.addEventListener('change', function(e){
                let result = { year : e.target.value }
                learning__updateState(result)
            })
        })
    }
}

function learning__updateTimeline(){
    let years = document.querySelectorAll('.year-timeline__year-btn');
    if ( years ){
        years.forEach( year => {
            if ( learning__state['year'] === year.value ){
                year.checked = true;
            }
        })
    }
}

function learning__updateResults(){

    let urlParam = window.location.search;

    //Get the page type
    let pageType = null;
    let pageTypeUrl = window.location.pathname; 

    //Remove a trailing slash if there is one 
    if (pageTypeUrl.endsWith('/')){
        pageTypeUrl = pageTypeUrl.slice(0, -1)
    }

    if (pageTypeUrl === '/learning-center') pageType = "learningIndex"
    if (pageTypeUrl === '/learning-center/interactives') pageType = "interactives"
    if (pageTypeUrl === '/learning-center/webinars') pageType = "webinars"
    if (pageTypeUrl === '/learning-center/technical-papers-presentations') pageType = "technicalPapersPresentations"

    //Add the page type
    if (urlParam === '?' || urlParam === '' || urlParam === null){
        urlParam = `?type=${pageType}`
    } else {
        urlParam = urlParam + `&type=${pageType}`
    }

    let searchString = `/views/hidden/search-ajax-request-learning${urlParam}`;

    $('.learning-loading').fadeIn();
    $('#query-container-learning').fadeOut();

    $.ajax(searchString, {
        dataType : 'html',
        success : function (response) {
            $('.learning-loading').fadeOut();
            var $html = $('<div />').append(response);
            $('#query-container-learning').html($html.find('.js-search-results--learning').html()).fadeIn();
            //Prep the popovers 
            var dynamic_popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
            var dynamic_popoverList =  dynamic_popoverTriggerList.map(function (popoverTriggerEl) {
                return new bootstrap.Popover(popoverTriggerEl)
            })
            $('#query-container-learning').fadeIn();

            if ($('.learning-center__interactives-slider')){
                $('.learning-center__interactives-slider').slick({
                    dots: true,
                    slidesToShow: 1,
                    appendDots: '.interactives-slider-controls__dots',
                    prevArrow: '.interactives-slider-controls__slider-prev',
                    nextArrow: '.interactives-slider-controls__slider-next',
                    arrowsPlacement: 'afterSlides'
                });
            }

             //Update type "View all" links with new params every time results load in
             let learningUrlParam;
             if(urlParam === `?type=${pageType}`) {
                learningUrlParam = urlParam.replace(`?type=${pageType}`, '')
             } else {
                learningUrlParam = urlParam.replace(`&type=${pageType}`, '')
             }
             updateLearningURLs(learningUrlParam);
            
        }
    });
}


// ------------ Initialize --------------------------

if (document.querySelector('#learning-search')){ 

    console.log("🔎 Running the Learning Search")

    learning__initalLoad()
    learning__watchFilters()

    const resetFiltersBtn = document.querySelector('.reset-all-filters__button');
    if(resetFiltersBtn){
        resetFiltersBtn.addEventListener('click', () => {
            learning__updateState(null, 'reset')
        })
    }
}



//Add updated params to each learning type "view all" links
function updateLearningURLs(learningUrlParam) {

    const technicalDownloadsURL = document.querySelector('.view-all-learning--technical-downloads');

    if(technicalDownloadsURL) {
        technicalDownloadsURL.href = `/learning-center/technical-papers-presentations${learningUrlParam}`
    }
}