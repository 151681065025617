//******************** */
// * Project Search *
//****************** */
/*
    1) On page load, get any url params
    2) Update project state object with params (state is our source of truth)
    2) then update the filters (dropdowns and search bar) based on state
    3) then ajax grab the projects, filtered by state
    4) watch the page filters for user interaction
    4) when the user interacts with a filter
        4.1) update state
        4.2) update the url params based on state
        4.3) re-grab projects, filtered by state
    5) when the user clicks 'reset filters', state gets reset except for search query

    The project filtration happens on a hidden page (/views/hidden/search-ajax-request-projects)
    Whenever projects are grabbed an ajax request is made to get the contents of that page
    (craft queries handled on separate page so the project index doesn't need to reload to run the queries)
    The hidden page accepts url params and uses those to filter the query it makes

    -------------------- STATE --------------------
    - the source of truth (vs params or active dropdowns)
    */

    let projects__state = {
        query:'',
        solution:'',
        capability:'',
        theme:'',
        region:''
    }

    /*
    -------------------- FUNCTIONS ------------------

    projects__initialLoad()
    - get params
    - updateState(stateChange, 'params')

    project__updateState(stateChange, source)
    - update the state key/value
        - stateChange is an object with just the changing keys, so you can update multiple in one go
        - e.g. stateChange = { theme:"Innovation", region:"Canada"}
    - source helps us determine what needs (and doesnt need) to be updated
    - if source === reset, return all values to null except query
    - if source !== params, update url params based on this change
    - if source !== filters, call updateFilters()
    - call updateResults()

    project__updateFilters()
    - reset all filters
    - update filters based off state

    project__resetFilters()
    - resets dom filters to defaults

    project__updateResults()
    - send an ajax query with the url param
    - get results
    - update DOM with results
    - reinitialize the popover library handle on minor projects

    project__watchFilters()
    - find all filters, add eventListeners for click and keypress
    - on interaction, call updateState(stateChange, 'filters')

*/

function projects__initalLoad(){

    const urlParams = new URLSearchParams(window.location.search);
    const queryParam = urlParams.get('query')
    const themeParam = urlParams.get('theme')
    const regionParam = urlParams.get('region')
    const solutionParam = urlParams.get('solution')
    const capabilityParam = urlParams.get('capability')

    let paramObject = {}

    if (queryParam){ paramObject['query'] = queryParam }
    if (themeParam){ paramObject['theme'] = themeParam.toLowerCase() }
    if (regionParam){ paramObject['region'] = regionParam.toLowerCase() }
    if (solutionParam){ paramObject['solution'] = solutionParam.toLowerCase() }
    if (capabilityParam){ paramObject['capability'] = capabilityParam.toLowerCase() }

    projects__updateState(paramObject)
}


function projects__updateState(stateChange, source){
    //Update the state with the new changes (or reset them)

    if (source === "reset"){
        projects__state['solution'] = ''
        projects__state['capability'] = ''
        projects__state['theme'] = ''
        projects__state['region'] = ''
    } else {
        if (stateChange !== null && typeof stateChange === 'object'){
            Object.entries(projects__state).forEach(([keyA, valueA]) => {
                Object.entries(stateChange).forEach(([keyB, valueB]) => {
                    if (keyA === keyB){
                        if (valueB === null){
                            projects__state[keyA] = ''
                        } else {
                            projects__state[keyA] = valueB
                        }
                    }
                })
            });
        }
    }

    if (source === "reset"){
        projects__resetFilters()
    }
    if (source !== "params"){
        let urlParams = new URLSearchParams('');
        let paramsAreEmpty = true;
        Object.entries(projects__state).forEach(([key, value]) => {
            if (value !== ''){
                urlParams.set(key, value)
                paramsAreEmpty = false;
            }
        })
        let showParamMark = paramsAreEmpty ? '' : '?'
        window.history.replaceState({}, '', `${window.location.pathname}${showParamMark}${urlParams}`);
    }
    projects__updateFilters()
    projects__updateResults()
}


function projects__resetFilters(){
    // Unhighlight any active dropdown items
    let activeDropdownItems = document.querySelectorAll('.dropdown-item--active');
    activeDropdownItems.forEach(item => {
        item.classList.remove('dropdown-item--active');
    })

    // Highlight the default "all __" option again
    document.querySelector('.all-region').classList.add('dropdown-item--active');
    document.querySelector('.all-theme').classList.add('dropdown-item--active');
    document.querySelector('.all-solution').classList.add('dropdown-item--active');
    document.querySelector('.all-capability').classList.add('dropdown-item--active');

    // Change all dropdown inner text to "All __"
    document.querySelector('.dropdown-toggle__selection--region').innerText = "All Regions";
    document.querySelector('.dropdown-toggle__selection--theme').innerText = "All Themes";
    document.querySelector('.dropdown-toggle__selection--solution').innerText = "All Solutions";
    document.querySelector('.dropdown-toggle__selection--capability').innerText = "All Capabilities";
}


function projects__updateFilters(){
    projects__resetFilters()
    //Loop through state and update the filter dropdowns of any active types
    Object.entries(projects__state).forEach(([key, value]) => {
        if (key !== 'query' && value !== ''){
            let filter = document.querySelector(`.${key}-option[data-${key}=${value}]`)
            if (filter){
                document.querySelector(`.all-${key}`).classList.remove('dropdown-item--active'); //first remove the all filter
                filter.classList.add('dropdown-item--active')
                let filterTitle = filter.querySelector('span').innerHTML;
                document.querySelector(`.dropdown-toggle__selection--${key}`).innerHTML = filterTitle;
            }
        }
        else if (key !== 'query' && value === ''){
            // Add active class to "all __" option when it is clicked (i.e. no filter)
            document.querySelector(`.all-${key}`).classList.add('dropdown-item--active');
        }
        else if (key === 'query' && value !== ''){
            document.querySelector('#projects-search').value = value;
        }
    })
}


function project__watchFilters(){

    ['region', 'theme', 'capability', 'solution'].forEach(( type ) => {
        let filters = document.querySelectorAll(`.${type}-option`);
        if (filters){
            filters.forEach((filter) => {
                filter.addEventListener('click', function(e){
                    if (type === 'capability' || type === 'solution'){
                        if(filter.classList.contains('is-open') || filter.classList.contains('is-open--nested')) {
                            return filter;
                        }
                    }
                    let result = {}
                    result[type] = e.currentTarget.getAttribute(`data-${type}`);
                    projects__updateState(result, 'filters')
                })
                filter.addEventListener('keypress', function(e){
                    if(e.key === "Enter") {
                        let result = {}
                        result[type] = e.currentTarget.getAttribute(`data-${type}`);
                        projects__updateState(result, 'filters')
                    }
                })
            })
        }
    })

    //Watch the search box
    $('#projects-search').keyup(_.debounce(function(event){
        let result = { query : event.target.value }
        projects__updateState(result)
    } , 500));
}


function projects__updateResults(){

    let urlParam = window.location.search;
    let searchString = `/views/hidden/search-ajax-request-projects${urlParam}`;

    $('.projects-loading').fadeIn();
    $('#query-container-projects').fadeOut();

    $.ajax(searchString, {
        dataType : 'html',
        success : function (response) {
            $('.projects-loading').fadeOut();
            var $html = $('<div />').append(response);
            $('#query-container-projects').html($html.find('.js-search-results--projects').html()).fadeIn();
            //Prep the popovers
            var dynamic_popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
            var dynamic_popoverList =  dynamic_popoverTriggerList.map(function (popoverTriggerEl) {
                return new bootstrap.Popover(popoverTriggerEl)
            })
            $('#query-container-projects').fadeIn();
        }
    });
}


// ------------ Initialize --------------------------

if (document.querySelector('#projects-search')){
    projects__initalLoad()
    project__watchFilters()

    const resetFiltersBtn = document.querySelector('.reset-all-filters__button');
    if(resetFiltersBtn){
        resetFiltersBtn.addEventListener('click', () => {
            projects__updateState(null, 'reset')
        })
    }
}

