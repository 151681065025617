

    /* DEBUGGING TIP : Keep the header open for debugging while inspecting by adding "debug" class to the <header>  */

    /* ----------------------------- */
    /* -- BROWSER WIDTH / RESIZE -- */
    /* --------------------------- */

    let KI_browserSize = null;

    function getBrowserWidth(){
        return window.innerWidth > 1000 ? "desktop" : "mobile";
    }

    KI_browserSize = getBrowserWidth(); //on load

    window.addEventListener('resize', function(event){
        let newBrowserSize = getBrowserWidth();
        if (KI_browserSize != newBrowserSize){
            //Any events that need to occur when changing between desktop and mobile

            // If mobile menu is open, then browser becomes desktop, remove excess mobile classes
            if (newBrowserSize === 'desktop' && KI_browserSize === 'mobile'){
                document.querySelector('body').classList.remove('body--fixed');
                document.querySelector('body').classList.remove('body-mobile-menu-open');
            }

            //Change about us between mobile and desktop
            updateAboutUsMenu();
            KI_browserSize = newBrowserSize;
        }
      });


    /* --------------------------- */
    /* -- HOVER INTENT LIBRARY -- */
    /* ------------------------- */

    const hoverIntent = (el, onOver, onOut) => {
        let x;
        let y;
        let pX;
        let pY;
        const h = {};
        let state = 0;
        let timer = 0;
    
        let options = {
            sensitivity: 6,
            interval: 80,
            timeout: 0,
            handleFocus: false,
            overClass: 'hovered'
        };
    
        const delay = e => {
            if (timer) timer = clearTimeout(timer);
            state = 0;
            if (onOut) {
                return onOut.call(el, e);
            }
            el.classList.remove(options.overClass);
            return false;
        };
    
        const tracker = e => {
            x = e.clientX;
            y = e.clientY;
        };
    
        const compare = e => {
            if (timer) timer = clearTimeout(timer);
            if (Math.abs(pX - x) + Math.abs(pY - y) < options.sensitivity) {
                state = 1;
                if (onOver) {
                    return onOver.call(el, e);
                }
                el.classList.add(options.overClass);
                return false;
            }
            pX = x;
            pY = y;
            timer = setTimeout(() => {
                compare(e);
            }, options.interval);
            return false;
        };
    
        // Public methods
    
        const dispatchOver = e => {
            if (timer) timer = clearTimeout(timer);
            el.removeEventListener('mousemove', tracker, false);
    
            if (state !== 1) {
                pX = e.clientX;
                pY = e.clientY;
    
                el.addEventListener('mousemove', tracker, false);
    
                timer = setTimeout(() => {
                    compare(e);
                }, options.interval);
            }
    
            return this;
        };
    
        const dispatchOut = e => {
            if (timer) timer = clearTimeout(timer);
            el.removeEventListener('mousemove', tracker, false);
    
            if (state === 1) {
                timer = setTimeout(() => {
                    delay(e);
                }, options.timeout);
            }
    
            return this;
        };
    
        if (el) {
            el.addEventListener('mouseover', dispatchOver, false);
            el.addEventListener('mouseout', dispatchOut, false);
        }
    
        h.options = opt => {
            options = { ...options, ...opt };
            return h;
        };
    
        h.remove = () => {
            if (!el) return;
            el.removeEventListener('mouseover', dispatchOver, false);
            el.removeEventListener('mouseout', dispatchOut, false);
        };
    
        return h;
    };


    /* ---------------------- */
    /* -- HELPER FUNCTIONS -- */
    /* ---------------------- */

    function mobileMenuIsOpen(){
        // console.log("mobile menu is... ")
        let header = document.querySelector('.header');
        if (header) {
            if (header.classList.contains('mobile-menu-is-open')){
                // console.log("OPEN")
                return true;
            } else {
                // console.log("CLOSED")
            }
        }
    }
    
    function openLevelTwo(megaNav){
        //Objective is to..
        // 1. Open up the navigation 'flow' of the entry (e.g. "conductors" has a l1 and l2 we want to higlight), if its part of the opened megamenu
        // 2. Otherwise open up the first available L1 menu, to fill that blank space 

        if (mobileMenuIsOpen()){
            return;
        } 

        //make sure only L0 is open at this point, avoid triggering while submenus are open 
        let openSubMenus = document.querySelector('.primary-nav__list-item[data--is-open][data-level="1"]')
        if (openSubMenus) {
            return;
        } 

        if (!megaNav){
            console.warn("Error finding active meganav")
            return;
        }

        //check if the entry already has a nav flow 
        let activeL1 = megaNav.querySelector('.primary-nav__list-item[data--is-active][data-level="1"]')
        if (activeL1) {
            activeL1.setAttribute('data--is-open', "");
            let activeL1Link = activeL1.querySelector('.primary-nav__link');
            if (activeL1Link) activeL1Link.setAttribute('aria-expanded', 'true');
            //update L2
            let activeL2 = activeL1.querySelector('.primary-nav__list-item[data--is-active][data-level="2"]')
            if (activeL2){
                activeL2.setAttribute('data--is-open', "");
                let activeL2Link = activeL2.querySelector('.primary-nav__link');
                if (activeL2Link) activeL2Link.setAttribute('aria-expanded', 'true');
            } 
        }
        else {
            //find the first L1 with children and open it 
            let openMegaNav = document.querySelector('.primary-nav__list-item[data--is-open][data-level="0"]');
            let navMenu = openMegaNav.querySelector('.primary-nav__menu[data-level="2"]'); 
            let navLink = openMegaNav.querySelector('.primary-nav__link[data-level="1"]')
            if (navMenu){
                let navItem = navMenu.closest('.primary-nav__list-item[data-level="1"]');
                if (navItem) {
                    navItem.setAttribute('data--is-open', '');
                }
                if (navLink){
                    navLink.setAttribute('aria-expanded', 'true');
                }
            }
        }
    }
    
    
    function equalizeMenuHeights(){
        // The three sections of menu need to have their heights always in sync
        // Because there is some absolute positioning, this requires code
        if (mobileMenuIsOpen()) return;

        //if the fixed right panel exists, consider its height as well
        let primaryNavLargestHeight = 0;
        let primaryNavMenus = document.querySelectorAll('[data--is-open] > .primary-nav__menu, [data--is-open] .primary-nav__fixed-right-panel');

        if (primaryNavMenus) {
            primaryNavMenus.forEach( menu => {
                menu.setAttribute("style","height: auto;"); //reset 
                let menuHeight = menu.offsetHeight;
                if (primaryNavLargestHeight < menuHeight){
                    primaryNavLargestHeight = menuHeight;
                }
            })

            let newMenuHeight = "height:" + primaryNavLargestHeight + "px";
            primaryNavMenus.forEach( menu => {
                menu.setAttribute("style", newMenuHeight);
            })

            let primaryNav = document.querySelector(".header");
            let primaryNavHeight;

            if (primaryNavLargestHeight !== 0) {
                primaryNavHeight = "height:" +  (primaryNavLargestHeight + 170) + "px;";
            } else {
                primaryNavHeight = "height:auto;";
            }
            primaryNav.setAttribute("style", primaryNavHeight)
        }
    }


    function toggleMegaNav(megaNav, forceAction, level){
        if (megaNav) {
            if (forceAction === 'close'){
                megaNav.parentElement.removeAttribute("data--is-open");
                let siblingLink = megaNav.parentElement.querySelector('.primary-nav__link');
                if (siblingLink){
                    siblingLink.setAttribute('aria-expanded', 'false');
                }
                megaNav.querySelectorAll('.primary-nav__list-item[data--is-open]').forEach( li => {
                    li.removeAttribute("data--is-open");
                    let link = li.querySelector('.primary-nav__link');
                    if (link) {
                        link.setAttribute('aria-expanded', 'false');
                    }
                })
                if (level === '0' && megaNav.closest('.primary-nav__list-item[data-level="0"]')) {
                    megaNav.closest('.primary-nav__list-item[data-level="0"]').classList.remove('active-megamenu')
                    document.querySelector('.header').classList.remove('active-meganav');
                }
            }
            else if (forceAction === 'open'){
                megaNav.parentElement.setAttribute("data--is-open", "");
                let siblingLink = megaNav.parentElement.querySelector('.primary-nav__link');
                if (siblingLink){
                    siblingLink.setAttribute('aria-expanded', 'true');
                }
                if (megaNav.closest('.primary-nav__list-item[data-level="0"]')) {
                    megaNav.closest('.primary-nav__list-item[data-level="0"]').classList.add('active-megamenu')
                }
                document.querySelector('.header').classList.add('active-meganav');
                openLevelTwo(megaNav);
            } 
            else {
                megaNav.parentElement.toggleAttribute("data--is-open");
                let siblingLink = megaNav.parentElement.querySelector('.primary-nav__link');
                if (siblingLink){
                    let toggleExpanded = siblingLink.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';
                    siblingLink.setAttribute('aria-expanded', toggleExpanded);
                    if (toggleExpanded === 'true'){
                        if (megaNav.closest('.primary-nav__list-item[data-level="0"]')) {
                            megaNav.closest('.primary-nav__list-item[data-level="0"]').classList.add('active-megamenu')
                        }
                        document.querySelector('.header').classList.add('active-meganav');
                        openLevelTwo(megaNav);
                    } else {
                        toggleMegaNav(megaNav, "close", level) 
                    }
                }
            }
        }
        else if (forceAction) {
            if (forceAction === 'close'){
                //This closes the entire meganav 
                document.querySelectorAll('.primary-nav__list-item[data--is-open]').forEach( li => {
                    li.removeAttribute('data--is-open')
                    if (li.closest('.primary-nav__list-item[data-level="0"]')) {
                        li.closest('.primary-nav__list-item[data-level="0"]').classList.remove('active-megamenu')
                    }
                    let link = li.querySelector('.primary-nav__link');
                    if (link){
                        link.setAttribute('aria-expanded', 'false');
                    }
                })
                if (document.querySelector('.header')) {
                    document.querySelector('.header').classList.remove('active-meganav');
                }
            }
   
        }
        equalizeMenuHeights();
    }


    //header resize with debounce 
    var headerResizeTimeout = false;
    var prevWasMobile = null;

    function handleResizeForNavigation() {
        let isMobile = window.innerWidth < 1000;
        if (isMobile !== prevWasMobile){
            if (!isMobile){
                //switching from a mobile browser width to a desktop browser width
                document.querySelector('header').classList.remove('mobile-menu-is-open'); 
                toggleMegaNav(null, 'close')   //shut it all down
            } else {
                document.querySelector('header').removeAttribute("style"); 
            }
            prevWasMobile = isMobile;
        }
    }

    // window.resize event listener
    window.addEventListener('resize', function() {
        clearTimeout(headerResizeTimeout);
        headerResizeTimeout = setTimeout(handleResizeForNavigation, 200);
    });


    /* --------------------------- */
    /* -- METHODS OF NAVIGATION -- */
    /* --------------------------- */
    // mouse . keyboard. mobile tap. 

    if (document.querySelector('.header')){
    //#1 - Navigate via Mouse

        //Close on mouseleave (comment this out code in this function for easy way of debugging the meganav)
        document.querySelector('.header').addEventListener('mouseleave' , (e) => {
            if (mobileMenuIsOpen()){
                return;
            }
            if (e.toElement && e.toElement.classList.contains('popover-arrow')){
                //In the scenario a minor project block is in the header, and a user selects the popover
            } else {
                let isDebugMode = document.querySelector('.header').classList.contains('debug') ?? false;
                if (!isDebugMode){
                    toggleMegaNav(null, 'close');
                    document.querySelector('header').removeAttribute("style"); //clear any JS set heights 
                }
            }
        
        })

        //Open and swap meganav levels with mouseover
        const onOverHandler = (e) => {

            if (mobileMenuIsOpen()) return; 
            
            let link = e.target;
            if (!link.classList.contains("primary-nav__link")){
                //If it is not targeting the correct link element, find it.
                link = link.closest('.primary-nav__link');
            }

            var linkLevel = link.getAttribute('data-level');
            var linkId = link.id;

            if (linkLevel && linkId){
                //Check for siblings that are already open and close them
                document.querySelectorAll(`.primary-nav__link[data-level='${linkLevel}']`).forEach( checkLink => {
                    let currentOpenMegaNav = checkLink.parentElement.querySelector('.primary-nav__menu');
                    if (currentOpenMegaNav && currentOpenMegaNav.parentElement.hasAttribute('data--is-open')){
                        if (checkLink.id !== linkId) {
                            toggleMegaNav(currentOpenMegaNav, 'close', linkLevel);
                        }
                    }
                })
                let toOpenMegaNav = link.parentElement.querySelector('.primary-nav__menu');
                if (toOpenMegaNav) {
                    toggleMegaNav(toOpenMegaNav, 'open', linkLevel)
                }
            } else {
                console.warn("Could not find navigation link to open")
            }
        }

        const onOutHandler = () => {
            // console.log('mouse out!');
            // do something 

        }

        // const customOptions = () => {
        //     return {
        //         sensitivity: 1000,
        //         interval: 1000,
        //         timeout: 0,
        //     }
        // }

        // const menuEl = document.querySelector('.menu');
        // hoverIntent(menuEl, onOverHandler, onOutHandler).options(customOptions);
        if (document.querySelector(".primary-nav__link")){
            document.querySelectorAll(".primary-nav__link").forEach(link => {
                hoverIntent(link, (e) => onOverHandler(e), onOutHandler).options();
            })
        } else {
            console.warn("Could not find primary links. Main navigation may not work as expected.")
        }
        


        //#2 - Navigate via Keyboard 

        document.querySelectorAll(".primary-nav__list-item[data-level='0']").forEach(link => {

            let relatedMegaNav = link.querySelector('.primary-nav__menu[data-level="1"]'); 
    
            link.addEventListener("keydown", (e) => {
                let activeLevel = document.activeElement.getAttribute('data-level');
                if (e.key === ' ' || e.key === 'Enter'){
                    if (e.target.hasAttribute('data--has-children')){
                            e.preventDefault();
                    }
                    if (activeLevel == '0'){
                        toggleMegaNav(relatedMegaNav, null, '0')
                    } 
                    else {
                        let nextLevelMegaNav = document.activeElement.parentElement.querySelector('.primary-nav__menu')   
                        if (nextLevelMegaNav) {
                            toggleMegaNav(nextLevelMegaNav)
                        }
                    }
                
                }
                if (e.key === 'ArrowRight'){
                    e.preventDefault();
                    if (activeLevel === '0'){
                        let nextMainLink = document.activeElement.parentElement.nextElementSibling;
                        if (nextMainLink) nextMainLink.querySelector('a').focus();
                    } else {
                        let nextMainLink = document.activeElement.closest('.primary-nav__menu[data-level="1"]').parentElement.nextElementSibling;
                        if (nextMainLink) nextMainLink.querySelector('a').focus();
                    }
                    toggleMegaNav(relatedMegaNav, 'close', '0')
                }
                if (e.key === 'ArrowLeft'){
                    e.preventDefault();
                    if (activeLevel === '0'){
                        let prevMainLink = document.activeElement.parentElement.previousElementSibling;
                        if (prevMainLink) prevMainLink.querySelector('a').focus();
                    } else {
                    
                        let prevMainLink = document.activeElement.closest('.primary-nav__menu[data-level="1"]').parentElement.previousElementSibling;
                        if (prevMainLink) prevMainLink.querySelector('a').focus();
                    }
                    toggleMegaNav(relatedMegaNav, 'close', '0')
                }
                if (e.key === 'ArrowDown'){
                    e.preventDefault();
                    if (!relatedMegaNav) return;
                    if (activeLevel === '0' && !relatedMegaNav.parentElement.hasAttribute('data--is-open') ){
                        toggleMegaNav(relatedMegaNav, "open") 
                    } 
                    else if (activeLevel === '0'){
                        let meganav = document.activeElement.parentElement.querySelector('.primary-nav__menu[data-level="1"]')
                        if (meganav) meganav.querySelector('a').focus()
                    }
                    else {
                        if (document.activeElement.classList.contains('primary-nav__index-link')){
                            document.activeElement.closest('.primary-nav__menu').querySelector('.primary-nav__list > li > a').focus()  
                        }
                        else {
                            if (document.activeElement.parentElement.nextElementSibling){
                                document.activeElement.parentElement.nextElementSibling.querySelector('a').focus()
                            }
                            else {
                                document.activeElement.closest('.primary-nav__menu').querySelector('.primary-nav__index-link').focus()
                            }
                        }
                    }
                }
                if (e.key === 'ArrowUp'){
                    e.preventDefault();
                    if (!relatedMegaNav) return;
                    if (activeLevel === '0' && relatedMegaNav.parentElement.hasAttribute('data--is-open') ){
                        toggleMegaNav(relatedMegaNav, 'close', '0') 
                    } 
                    else if (activeLevel !== '0') {
                        if (document.activeElement.parentElement.previousElementSibling){
                            document.activeElement.parentElement.previousElementSibling.querySelector('a').focus()
                        }
                        else {
                            if (document.activeElement.classList.contains('primary-nav__index-link')){
                                document.activeElement.closest('li').querySelector('.primary-nav__link').focus()
                                if (activeLevel === '1'){
                                    toggleMegaNav(relatedMegaNav, 'close')
                                }
                            }
                            else {
                                if (document.activeElement.closest('.primary-nav__menu').querySelector('.primary-nav__index-link')) {
                                    document.activeElement.closest('.primary-nav__menu').querySelector('.primary-nav__index-link').focus()
                                }
                            }
                        }
                    }
                }
            })

            link.addEventListener("keyup", (e) => {
                //on 'keyup' the focus has shifted to the new element
                //check to see if we need to close any open meganavs because focus has shifted off it using Tab
                let activeLevel = document.activeElement.getAttribute('data-level');

                if (e.key === 'Tab' && !mobileMenuIsOpen()){
                    document.querySelectorAll('.primary-nav__menu[data-level="0"]').forEach( nav => {
                        if ( nav.hasAttribute('data--is-open') ){
                            if (!nav.contains(document.activeElement)) {
                                toggleMegaNav(nav, 'close', activeLevel)
                            }
                        }
                    })
                    //Close any nested meganav levels that are deeper than the new focus level
                    document.querySelectorAll('.primary-nav__list-item[data--is-open]').forEach( li => {
                        let nav = li.querySelector('.primary-nav__menu');
                        if (nav){
                            if ( nav.getAttribute('data-level')) {
                                if (parseInt(activeLevel) < parseInt(nav.getAttribute('data-level'))) {
                                    toggleMegaNav(nav, 'close', activeLevel)
                                }
                            }
                        } else {
                            console.log("Cannot find nav item")
                        }
                    })
                }
            })

            //close primary nav when leaving it 
            link.addEventListener("keydown", (e) => {
                if (e.key === 'Tab' && e.shiftKey && !mobileMenuIsOpen()){
                    if (link.getAttribute('data-name') == 'Solutions'){
                        let solutionLink = link.querySelector('.primary-nav__link[data-level="0"]')
                        if (solutionLink){
                            if (document.activeElement === solutionLink){
                                toggleMegaNav(null, 'close');
                            }
                        }
                    }
                }
                if (e.key === 'Tab' && !mobileMenuIsOpen()){
                    if (link.getAttribute('data-name') == 'Careers'){
                        let anchors = link.querySelectorAll('*[href]');
                        if (anchors){
                            let lastAnchor = anchors[anchors.length - 1];
                            if (document.activeElement === lastAnchor){
                                //Leaving megamenu, close navs
                                toggleMegaNav(null, 'close');
                            }
                        }
                    }
                }
            })


        })

        //Leave Main Nav from internal close button
        // let mainNavCloseBtn = document.querySelector('.primary-nav__close-btn');
        // if (mainNavCloseBtn) {
        //     mainNavCloseBtn.addEventListener("click", function(e){
        //         toggleMegaNav(null, 'close');
        //     })
        // }

        //#2 - Navigate via Mobile tap 
        /* When the user is scrolling (touchmove) change the 'mobile_preventOpen' variable so that links CANNOT be opened */
        var mobile_preventOpen = false 
        var mobile_preventOpenDebounce;

        document.querySelectorAll('.desktop-navs').forEach( link => {
            link.addEventListener('touchmove', (e) => {
                mobile_preventOpen = true;
                mobile_preventOpenDebounce = window.setTimeout(function() {
                    mobile_preventOpen = false;
                    window.clearTimeout(mobile_preventOpenDebounce);
                }, 100);
            })
        })

        //Mobile menu with tap
        document.querySelectorAll('.primary-nav__link').forEach( link => {
            link.addEventListener('touchend', (e) => {
                if (mobileMenuIsOpen()){
                    if (!mobile_preventOpen){
                        if (link.hasAttribute('data--has-children')){
                            e.preventDefault();
                            let nav = link.parentElement.querySelector('.primary-nav__menu');
                            if (nav) {
                                toggleMegaNav(nav)
                            }
                        }
                    } 
                }
            })
        })

        //Mobile menu with mouse click
        document.querySelectorAll('.primary-nav__link').forEach( link => {
            link.addEventListener('click', (e) => {
                if (mobileMenuIsOpen()){
                    if (!mobile_preventOpen){
                        if (link.hasAttribute('data--has-children')){
                            e.preventDefault();
                            let nav = link.parentElement.querySelector('.primary-nav__menu');
                            if (nav) {
                                toggleMegaNav(nav)
                            }
                        }
                    } 
                }
            })
        })
    }

    /* ---------------------- */
    /* -- MOBILE SETUP -- */
    /* ---------------------- */

    let KI_aboutUs = document.querySelector(".primary-nav__main-menu > .primary-nav__list-item[data-name='About Us']");

    function updateAboutUsMenu(){
        //About Us needs to be cleaned up because it looks like a lot of duplicates on mobile     
        if (KI_aboutUs){
            let aboutUsListItem = KI_aboutUs.querySelector('.primary-nav__list-item[data-name="About Us"]');
            let aboutUsToggleLink = KI_aboutUs.querySelector('.primary-nav__link[data-level="1"]');
            let aboutUsMenu = KI_aboutUs.querySelector('.primary-nav__menu[data-level="2"]');
            if (aboutUsListItem && aboutUsToggleLink && aboutUsMenu){
                if (getBrowserWidth() === 'mobile'){
                    aboutUsListItem.setAttribute('data--is-open', true);
                    aboutUsToggleLink.classList.add("mobile-nav-aboutus--hide");
                    aboutUsMenu.classList.add("mobile-nav-aboutus--open");
                } else {
                    aboutUsListItem.removeAttribute('data--is-open');
                    aboutUsToggleLink.classList.remove("mobile-nav-aboutus--hide");
                    aboutUsMenu.classList.remove("mobile-nav-aboutus--open");
                }
        
            }
        }
    }
    updateAboutUsMenu();

    if (KI_aboutUs){
        KI_aboutUs.querySelector('.primary-nav__link').addEventListener("click", function(){
            if (KI_aboutUs.hasAttribute('data--is-open')){
                if (getBrowserWidth() === 'mobile'){
                    updateAboutUsMenu();
                }
            }
        })
        KI_aboutUs.querySelector('.primary-nav__link').addEventListener("touchend", function(){
            if (KI_aboutUs.hasAttribute('data--is-open')){
                if (getBrowserWidth() === 'mobile'){
                    updateAboutUsMenu();
                }
            }
        })
    }

    function toggleMobileMenu(){
        let header = document.querySelector('.header')
        if (header) {

            // if (document.querySelector('body').classList.contains('is-searching') && !header.classList.contains('mobile-menu-is-open')){
            //     $('#search-modal').modal('hide')
            // }

            header.classList.toggle('mobile-menu-is-open');
            header.classList.remove('active-meganav');
            //if any menus were already open, close them
            header.querySelectorAll('.active.open').forEach( openMenu => {
                openMenu.classList.remove('open');
            })
            document.querySelector('body').classList.toggle('body--fixed');
            document.querySelector('body').classList.toggle('body-mobile-menu-open');       
        } 
    }
    
    if (document.querySelector('.js-toggle-mobile-nav')) {
        document.querySelector('.js-toggle-mobile-nav').addEventListener('click', function(){
            if ($('.header').hasClass('is-compressed')){
                $('.header').removeClass('is-compressed');
            }
            toggleMobileMenu();
        })
    }

    if (document.querySelector('.mobile-nav__search.js-open-search')) {
        document.querySelector('.mobile-nav__search.js-open-search').addEventListener('click', function(){
            if ($('.header').hasClass('mobile-menu-is-open')){
                toggleMobileMenu();
            }
        })
    }

    /* ------------------------------ */
    /* ------- SECONDARY NAV ------- */
    /* ----------------------------- */

    if (document.querySelector('.secondary-nav')){ 
        document.querySelector('.secondary-nav').addEventListener('mouseenter', (e) => {
            //opening the submenu on hover is coverd in the scss
            if (!mobileMenuIsOpen()){
                toggleMegaNav(null, 'close');
            }
        })
        document.querySelectorAll('.secondary-nav__link').forEach( link => {
            link.addEventListener('mouseenter', (e) => {
                if (link.hasAttribute('data--has-children')){
                    let linkButton = link.parentElement.querySelector('.secondary-nav__dropdown-chevron');
                    if (linkButton){
                        linkButton.setAttribute('aria-expanded', 'true');
                    }
                }
            })
            link.addEventListener('mouseleave', (e) => {
                if (link.hasAttribute('data--has-children')){
                    let linkButton = link.parentElement.querySelector('.secondary-nav__dropdown-chevron');
                    if (linkButton){
                        linkButton.setAttribute('aria-expanded', 'false');
                    }
                }
            })
            link.addEventListener('touchstart', (e) => {
                if (link.hasAttribute('data--has-children')){
                    e.preventDefault();
                    link.parentElement.toggleAttribute('data--is-open');
                    let linkButton = link.parentElement.querySelector('.secondary-nav__dropdown-chevron');
                    if (linkButton){
                        let toggleExpanded = linkButton.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';               
                        linkButton.setAttribute('aria-expanded', toggleExpanded);
                    }
                
                }
            })
            link.addEventListener('click', (e) => {
                if (link.hasAttribute('data--has-children')){
                    if (mobileMenuIsOpen()){
                        e.preventDefault();
                        link.parentElement.toggleAttribute('data--is-open');
                        let linkButton = link.parentElement.querySelector('.secondary-nav__dropdown-chevron');
                        if (linkButton){
                            let toggleExpanded = linkButton.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';               
                            linkButton.setAttribute('aria-expanded', toggleExpanded);
                        }
                    }
                }
            })
        })
    }


                 
                

    let secondaryNavToggles = document.querySelectorAll('.js-toggle-secondary-nav');

    if (secondaryNavToggles){
        secondaryNavToggles.forEach( navToggle => {

            let submenu = navToggle.parentElement.querySelector('.secondary-nav__menu');

            if (submenu){
                let firstChild = submenu.querySelector('.secondary-nav__list-item:first-child');
                let lastChild = submenu.querySelector('.secondary-nav__list-item:last-child');
    
                if (firstChild){
                    firstChild.addEventListener('keydown', function(e){
                        if (e.key === 'Tab' && e.shiftKey){
                            if (submenu) {
                                submenu.removeAttribute('data--is-open');
                                navToggle.setAttribute('aria-expanded', 'false');
                            }
                        }
                    })
                } 
            
                if (lastChild){
                    lastChild.addEventListener('keydown', function(e){
                        if (e.key === 'Tab'){
                            if (submenu) {
                                submenu.removeAttribute('data--is-open');
                                navToggle.setAttribute('aria-expanded', 'false');
                            }
                        }
                    })
                } 
    
                //Toggle nav button open and closed 
                navToggle.addEventListener('keydown', function(e){
                    if (e.key === ' ' || e.key === 'Enter'){
                        if (submenu) {
                            submenu.toggleAttribute('data--is-open');
                            let toggleExpanded = navToggle.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';               
                            navToggle.setAttribute('aria-expanded', toggleExpanded);
                        }
                    }
                })

                //Go backward off of an open nav not yet entered
                navToggle.addEventListener('keydown', function(e){
                    if (e.key === 'Tab' && e.shiftKey){
                        if (submenu) {
                            submenu.removeAttribute('data--is-open');
                            navToggle.setAttribute('aria-expanded', 'false');
                        }
                    }
                })
            }
        })
    }



/* ------------------------------ */
/* ------- GTM ADDITIONS ------- */
/* ----------------------------- */

if (document.querySelector('.header')){
    let gtmProjectBtns = document.querySelectorAll('.primary-nav__list-item[data-name="Projects"] .primary-nav__fixed-right-panel .button');
    let gtmAboutBtns = document.querySelectorAll('.primary-nav__list-item[data-name="About Us"] .primary-nav__fixed-right-panel .button');
    let gtmCareersBtns = document.querySelectorAll('.primary-nav__list-item[data-name="Careers"] .primary-nav__fixed-right-panel .button');

    if (gtmProjectBtns.length > 0){
        gtmProjectBtns.forEach( button => {
            button.classList.add('analytics-main-nav__featured-project')
        })
    }

    if (gtmAboutBtns.length > 0){
        gtmAboutBtns.forEach( button => {
            button.classList.add('analytics-main-nav__featured-about')
        })
    }

    if (gtmCareersBtns.length > 0){
        gtmCareersBtns.forEach( button => {
            button.classList.add('analytics-main-nav__featured-careers')
        })
    }
}

 