// Prevent clicking in dropdown from closing it
$(".filter-search__dropdowns .dropdown-menu").click(function (e) {
    e.stopPropagation();
})

// On click of FIRST LEVEL "more button"
$(".filter-search__dropdowns .dropdown-menu .dropdown-item__toggle-accordion ").click(function (e) {
    //alert($(this).attr('aria-expanded'));
    if ($(this).attr('aria-expanded') == 'false') {
        $(this).attr('aria-expanded', true);
        $(this).parents('.dropdown-item').addClass('is-open');
        $(this).parent('.dropdown-item').parent('ul').css("transform", "translateX(-100%)");

        //When second level dropdown is open, make the parent dropdown items untabbable
        $(this).parent('.dropdown-item').parent('ul').children().attr('tabIndex', '-1');
        $(this).parent('.dropdown-item').parent('ul').children().children('.dropdown-item__toggle-accordion').attr('tabIndex', '-1');
        $(this).parents('.dropdown-menu').scrollTop(0);
    }
});

// On click of SECOND LEVEL "more button"
$(".dropdown-item .dropdown-item__accordion .dropdown-item__toggle-accordion--nested").click(function (e) {
    //alert($(this).attr('aria-expanded'));
    if ($(this).attr('aria-expanded') == 'false') {
        $(this).attr('aria-expanded', true);
        $(this).parent('li').addClass('is-open--nested');
        $(this).parent('li').parent('ul').parent('li').parent('ul').css("transform", "translateX(-200%)");
        
        //When third level dropdown is open, make the parent dropdown items untabbable
        $(this).parent('li').parent('ul').children().attr('tabIndex', '-1');
        $(this).parent('li').parent('ul').children().children('.dropdown-item__toggle-accordion--nested').attr('tabIndex', '-1');
        $(this).parents('.dropdown-menu').scrollTop(0);
    } 
});


// On click of SECOND LEVEL "back button"
// slide back to previous level + make sure nested list is display none again
$(".dropdown-item__accordion .back-button").click(function() {
    $(this).parent('.dropdown-item__accordion').parent('.dropdown-item').parent('ul').css("transform", "translateX(0%)");

    setTimeout(() => {
        $(this).parent('.dropdown-item__accordion').prev().attr('aria-expanded', false);
        $(this).parent('.dropdown-item__accordion').parent('.dropdown-item').removeClass('is-open');
    }, 500);

    // Make parent dropdown items clickable again when going back to the first level dropdown
    $(this).parent('.dropdown-item__accordion').parent('li').parent('ul').children().attr('tabIndex', '0');
    $(this).parent('.dropdown-item__accordion').parent('li').parent('ul').children().children('.dropdown-item__toggle-accordion').attr('tabIndex', '0');
})

// On click of THIRD LEVEL "back button"
// slide back to previous level + make sure nested list is display none again
$(".dropdown-item__accordion--nested .back-button--nested").click(function() {
    $(this).parent('.dropdown-item__accordion--nested').parent('li').parent('ul').parent('.dropdown-item').parent('ul').css("transform", "translateX(-100%)");

    setTimeout(() => {
        $(this).parent('.dropdown-item__accordion--nested').prev().attr('aria-expanded', false);
        $(this).parent('.dropdown-item__accordion--nested').parent('li').removeClass('is-open--nested');
    }, 500);

    // Make parent dropdown items clickable again when going back to the second level dropdown
    $(this).parent('.dropdown-item__accordion--nested').parent('li').parent('ul').children().attr('tabIndex', '0');
    $(this).parent('.dropdown-item__accordion--nested').parent('li').parent('ul').children().children('.dropdown-item__toggle-accordion--nested').attr('tabIndex', '0');
})




// Change dropdown text to be the selected option

// - Region
const regions = document.querySelectorAll(".dropdown-item.region-option");

for (let i = 0; i < regions.length; i++) {
    const dropdownRegionButtonText = document.querySelector(".dropdown-toggle__selection--region");

    regions[i].addEventListener('click', () => {
        dropdownRegionButtonText.innerText = regions[i].innerText;
    })

    regions[i].addEventListener('keypress', (event) => {
        if(event.key === "Enter") {
            dropdownRegionButtonText.innerText = regions[i].innerText;
        }
    })
}

// - Theme
const themes = document.querySelectorAll(".dropdown-item.theme-option");

for (let i = 0; i < themes.length; i++) {
    const dropdownThemeButtonText = document.querySelector(".dropdown-toggle__selection--theme");

    themes[i].addEventListener('click', () => {
        dropdownThemeButtonText.innerText = themes[i].innerText;
    })

    themes[i].addEventListener('keypress', (event) => {
        if(event.key === "Enter") {
            dropdownThemeButtonText.innerText = themes[i].innerText;
        }
    })
}

// - Innovation Topics
const innovationTopics = document.querySelectorAll(".dropdown-item.innovation-option");

for (let i = 0; i < innovationTopics.length; i++) {
    const dropdownInnovationButtonText = document.querySelector(".dropdown-toggle__selection--innovation");

    innovationTopics[i].addEventListener('click', () => {
        dropdownInnovationButtonText.innerText = innovationTopics[i].innerText;
    })

    innovationTopics[i].addEventListener('keypress', (event) => {
        if(event.key === "Enter") {
            dropdownInnovationButtonText.innerText = innovationTopics[i].innerText;
        }
    })
}

// - Solution
const solutions = document.querySelectorAll(".solution-option");

for (let i = 0; i < solutions.length; i++) {
    const dropdownSolutionButtonText = document.querySelector(".dropdown-toggle__selection--solution");

    solutions[i].addEventListener('click', () => {
        // return the parent option(s), so that the child is selected when clicked
        if(solutions[i].classList.contains('is-open') || solutions[i].classList.contains('is-open--nested')) {
            return solutions[i];
        }
        
        // Grab the span (.children[0]) instead of the button's entire innerText to avoid also displaying the number of children
        dropdownSolutionButtonText.innerText = solutions[i].children[0].innerText;
    })

    solutions[i].addEventListener('keypress', (e) => {
        if(e.key === "Enter") {
            // return the parent option(s), so that the child is selected when clicked
            if(solutions[i].classList.contains('is-open') || solutions[i].classList.contains('is-open--nested')) {
                return solutions[i];
            }
            
            // Grab the span (.children[0]) instead of the button's entire innerText to avoid also displaying the number of children
            dropdownSolutionButtonText.innerText = solutions[i].children[0].innerText;
        }
    })
}

// - Capability
const capabilities = document.querySelectorAll(".capability-option");

for (let i = 0; i < capabilities.length; i++) {
    const dropdownCapabilityButtonText = document.querySelector(".dropdown-toggle__selection--capability");

    capabilities[i].addEventListener('click', () => {
        // return the parent option(s), so that the child is selected when clicked
        if(capabilities[i].classList.contains('is-open') || capabilities[i].classList.contains('is-open--nested')) {
            return capabilities[i];
        }

        // Grab the span (.children[0]) instead of the button's entire innerText to avoid also displaying the number of children
        dropdownCapabilityButtonText.innerText = capabilities[i].children[0].innerText;
    })
    capabilities[i].addEventListener('keypress', (e) => {
        if (e.key === "Enter") {
            // return the parent option(s), so that the child is selected when clicked
            if(capabilities[i].classList.contains('is-open') || capabilities[i].classList.contains('is-open--nested')) {
                return capabilities[i];
            }
    

            // Grab the span (.children[0]) instead of the button's entire innerText to avoid also displaying the number of children
            dropdownCapabilityButtonText.innerText = capabilities[i].children[0].innerText;
        }
    })
}