//Modal

const searchModal = document.getElementById('search-modal')

searchModal.addEventListener('show.bs.modal', () => {
    $('body').addClass('is-searching');
    if ($('body').hasClass('body-mobile-menu-open')){
        $('body').removeClass('body--fixed');
    }
})

searchModal.addEventListener('shown.bs.modal', () => {
    $( ".js-close-search" ).focus();
})

searchModal.addEventListener('hidden.bs.modal', () => {
    $('body').removeClass('is-searching');
    if ($('body').hasClass('body-mobile-menu-open')){
        $('body').addClass('body--fixed');
    }
})

//Search

$(document).ajaxStart(function(){
    $('.ajax-loader').fadeIn();
});
$(document).ajaxComplete(function(){
    $('.ajax-loader').fadeOut();
});
$("#search").keyup(function(event) {
    if (event.keyCode === 13) {
        $("#submit-filters").click();
    }
});

$('#search').keyup(_.debounce(doSearch, 500));
$('#search').keyup(_.debounce(handleDatalayerSearch , 2000));

var historySupport = !!(window.history && history.pushState);

function doSearch(){
    var search = $('#search').val();
    if (search.length == 0){
        $('#query-container').fadeOut();
    } else {
        if($('#search')[0].checkValidity()){
            ajaxCall(search);
        }
        else{
            $.fancybox.open($("#search-error"));
        }
    }

}

function handleDatalayerSearch(){
    var search = $('#search').val();
    if (search.length > 2){
        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({  
                'event': 'main_site_search',  
                'search_term': search,  
            }); 
        }
    }
}

function handleDatalayerSearchResults(searchQuery){
    let searchResults = document.querySelectorAll('.search-result a');
    if (searchResults){
        searchResults.forEach( result => {
            result.addEventListener('click', function(e){
                e.preventDefault();
                e.stopPropagation();
                let linkText = result.querySelector('.search-result__title').innerText;
                let linkUrl = result.href;
                window.dataLayer.push({ 
                    'event': 'main_site_search_result', 
                    'search_term': searchQuery, 
                    'link_text': linkText, 
                    'link_url': linkUrl
                    // 'eventCallback': () =>  { 
                    //     if (result.getAttribute('target') === '_blank'){
                    //         window.open(linkUrl);
                    //     } else {
                    //         window.location = linkUrl; 
                    //     }
                    // },
                    // 'eventTimeout' : 2000 
                }); 
                //Including the settimeout causes some devices to open it multiple times??? 
                setTimeout(() => {
                    if (result.getAttribute('target') === '_blank'){
                        window.open(linkUrl);
                    } else {
                        window.location = linkUrl; 
                    }
                }, 400);
            })
        })
    }
}

function ajaxCall(search){
    $('.search-panel__loading').fadeIn();
    $('#query-container').fadeOut();
    $.ajax('/views/hidden/search-ajax-request?&q='+search, {
        dataType : 'html',
        success : function (response) {
            $('.search-panel__loading').fadeOut();
            refreshResults(response, search);
            $('#query-container').fadeIn();
            initSearchFiltering();
            if (typeof window.dataLayer !== "undefined") {
                handleDatalayerSearchResults(search)
            }
        }
    });
}

function refreshResults (html, search) {
    var $html = $('<div />').append(html);
    $('#query-container').html($html.find('.js-search-results').html()).fadeIn();
}

// Filtering

function initSearchFiltering(){

    let listOfFilters = document.querySelectorAll('.search-filters__filter-li button');
    let searchActiveFilter = 'all';

    if (listOfFilters) {
        listOfFilters.forEach( filter => {
            filter.addEventListener('click', function(e){
                searchActiveFilter = e.currentTarget.getAttribute('data-filter');
                updateSearchFilter(searchActiveFilter)
            })
        })
        updateSearchFilter('all')
    }

    let sortSelect = document.querySelector('.js-search-sort');
    if (sortSelect) {
        sortSelect.addEventListener('change', function(e){
            sortSearch(e.target.value)
        })
        sortSearch('relevance'); //initial sort
    }
}

function updateSearchFilter(filterToSet){

    //reset
    document.querySelectorAll('.active-search-filter').forEach( filter => {
        filter.classList.remove('active-search-filter')
    })
    document.querySelectorAll('.search-result').forEach( result => {
        result.classList.remove('search-result--hide')
    })

    //add class to filter button
    let newFilter = document.querySelector(`.search-filters__filter-li button[data-filter="${filterToSet}"]`);
    if (newFilter){
        newFilter.classList.add('active-search-filter')
    }

    let searchFilter = filterToSet;
    switch (searchFilter) {
        case 'capabilities':
            searchFilter = 'capability';
            break;
        case 'solutions':
            searchFilter = 'solution';
            break;
        case 'projects':
            searchFilter = 'project';
            break;
    }

    //filter list
    if (searchFilter !== 'all'){
        document.querySelectorAll('.search-result').forEach( result => {
            if (result.getAttribute('data-result-type') !== searchFilter ){
                result.classList.add('search-result--hide')
            }
        })
    }

    //update number of found results
    let results = document.querySelector('.search-results__total');
    let numberOfResults = document.querySelectorAll('.search-result:not(.search-result--hide)');
    if (results && numberOfResults) {
        results.innerHTML = numberOfResults.length + " Results"
    }

}

function sortSearch(sortType){
    let results = document.querySelectorAll('.search-result');
    if (!results) return;

    results = Array.from(results);

    if (sortType == 'relevance'){
        results.sort((a, b) => b.getAttribute('data-score') - a.getAttribute('data-score'))
    }

    if (sortType == 'most-recent'){
        results.sort((a, b) => Date.parse(b.getAttribute('data-date')) - Date.parse(a.getAttribute('data-date')))
    }

    let resultsList = document.querySelector("#search-results-scroller .search-results__list");
    if (resultsList) {
        for (let result of results) {
            resultsList.appendChild(result)
        }
    }
}

function toggleMobileFilters(){
    if ($('.search-filters').length < 1){
        $('.search-modal__mobile-filters-warning').addClass("search-modal__mobile-filters-warning--active")
    } else {
        $('.search-modal__mobile-filters-warning').removeClass("search-modal__mobile-filters-warning--active")
        $('.search-modal__mobile-filter-toggle').toggleClass('search-modal__mobile-filter-toggle--active');
        $('#query-container').toggleClass('show-filters');
    }
}

let mobileSearchFilters = document.querySelector(".js-search-mobile-filter-toggle");
if (mobileSearchFilters){
    mobileSearchFilters.addEventListener("click", function(){
        toggleMobileFilters();
    })
}